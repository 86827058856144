import React from 'react';

const Shorten = ({ text, maxLength }) => {
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...'; // Adiciona '...' se o texto for truncado
        }
        return text; // Retorna o texto original se não precisar ser truncado
    };

    const truncatedText = truncateText(text, maxLength);
    
    return (
        <>
            {truncatedText}
        </>
    );
};

export default Shorten;
