import React, { useContext, useEffect, useState, Fragment, lazy, Suspense } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSwaarm } from "../components/functions/GetSwaarm";
import ShowCardsHome from "./categorias/ShowCardsHome";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { verifyUser } from "../components/functions/VerifyUser";
import ShowCardsDestaques from "./categorias/ShowCardsDestaques";
import { addNewAdEvent } from "../components/functions/AddNewAdEvent";
import { getUserGeoData } from "../helpers/users";
import SeoTags from "./common/SeoTags";
import ShowCardPremium from "./categorias/ShowCardPremium";
import { addUserIpTracking, UpdateUserLoginIp } from "../components/functions/AddUserIpTracking";
import { useTranslation } from "react-i18next";
import { getBanner } from "../components/functions/GetBanner";

// Lazy loading dos componentes
//const ShowCardsHome = lazy(() => import("./categorias/ShowCardsHome"));
const Parceiros = lazy(() => import("./common/Parceiros"));
const Stats = lazy(() => import("./common/Stats"));
const AboutBox = lazy(() => import("./common/AboutBox"));
const FaqHome = lazy(() => import("./common/FaqHome"));
//const ShowCardPremium = lazy(() => import("./categorias/ShowCardPremium"));


const Home = () => {
     const { usuario, setUsuario, idPais } = useContext(AuthContext)
     const { email, uid } = usuario || {}
     const [showJoinComponent, setShowJoinComponent] = useState(true)
     const [showJoinMain, setShowJoinMain] = useState(true)
     const [swaarmOffers, setSwaarmOffers] = useState(null)
     const [signupres, setSignupres] = useState(null)
     const navigate = useNavigate();
     let id_Paes = null;
     const [showSM, setShowSM] = useState(false)
     const { t } = useTranslation()

     // Variáveis do Banner
     const [mobilePath, setMobilePath] = useState(false)
     const [desktopPath, setDesktopPath] = useState(false)
     const [offerDetailUrl, setOfferDetailUrl] = useState(false)

     // Resgata track click id do localstorage (ADS TRACKING)
     const adsTrackClickId = localStorage.getItem('sbxclickid')

     // TAGS SEO
     const title = t('homeseotitle')
     const description = t('homeseodesc')

     // Coleta os dados geográficos o user
     async function fetchUserGeoData() {
          try {
               const userGeoData = await getUserGeoData();
               const nomePais = userGeoData.countryName;
               const codPais = userGeoData.countryCode;
               return { nomePais, codPais };
          } catch (error) {
               console.log(error)
          }
     }

     async function getPaisData(code, name) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-pais/" + code + "/" + name)
               const data = await response.json()
               const id_pais = data.cData.id_pais
               return id_pais
          } catch (error) {
               console.log(error)
          }
     }

     useEffect(() => {
          getSwaarm(7, 3)
               .then((swaarmData) => {
                    setSwaarmOffers(swaarmData)
               })
               .catch((error) => {
                    console.error("Erro ao buscar dados do Swaarm:", error);
               });
     }, []);

     // Sorteia slide do país
     useEffect(() => {
          if (idPais !== "loading") {
               // Acessa api que retorna paths dos banners (desktop e mobile)
               getBanner(idPais)
                    .then((bannerData) => {
                         setDesktopPath(bannerData.path_desk)
                         setMobilePath(bannerData.path_mobi)
                         setOfferDetailUrl(bannerData.url)
                    })
                    .catch((error) => {
                         console.error("Erro ao buscar dados do banner:", error);
                    });

          }
     }, [idPais])

     const signupGoogle = async () => {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          signInWithPopup(auth, provider)
               .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    const uid = user.uid
                    setUsuario(user)

                    // Verificar se usuário existe na tabela
                    verifyUser(uid).then(resultado => {
                         if (!resultado) {
                              // se não encontrou uid, grava dados na tabela

                              // atribuição de país
                              async function getDados() {
                                   try {
                                        const { nomePais, codPais } = await fetchUserGeoData();

                                        try {
                                             const idPaiz = await getPaisData(codPais, nomePais)
                                             id_Paes = idPaiz;
                                        } catch (erro) {
                                             console.error("Erro ao obter dados do país em banco de dados:", erro)
                                        }
                                   } catch (error) {
                                        console.error("Erro ao obter dados geográficos do usuário:", error);
                                   }
                              }
                              (async function () {
                                   await getDados(); // Aguarde a promessa ser resolvida
                                   // This gives you a Google Access Token. You can use it to access the Google API.
                                   const credential = GoogleAuthProvider.credentialFromResult(result);
                                   const token = credential.accessToken;
                                   // The signed-in user info.
                                   const user = result.user;
                                   const email = user.email;
                                   setUsuario(user)
                                   const { uid, providerId } = user || {};
                                   const user_status = 1

                                   createUser(uid, email, providerId, user_status, id_Paes);
                                   addUserIpTracking(uid)
                                   UpdateUserLoginIp(uid)

                              })();

                         } else {
                              UpdateUserLoginIp(uid)
                         }
                    });

                    navigate("/")
               }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    // const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    setSignupres(credential)
                    console.log("Erro login:", error)
               });
     }

     // Postback de conversão
     const enviarPostback = async (idClick, idEvent) => {
          const url = 'https://conversions.kypi.io/postback?click_id=' + idClick + '&security_token=113432ed-a639-4bd8-8c46-fac84b818b91&event_id=' + idEvent;
          try {
               await fetch(url, { method: 'HEAD' });
          } catch (error) {
               // gravar log de erro
          }
     };

     async function createUser(uid, email, provider, user_status, id_country) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/user-create", {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid, email, provider, user_status, id_country }),
               });
               // aqui verifica se a api gravou os dados
               const data = await response.json();
               if (data.sucesso === true) {

                    // [JUL-2024] - grava evento em ads_tracking
                    if (adsTrackClickId) {
                         addNewAdEvent(adsTrackClickId, uid, "signup")
                         enviarPostback(adsTrackClickId, "signup")
                    }

               } else if (data.existe === "sim") {
                    // não faz nada
               } else {
                    console.error("Erro ao criar o registro:", data.error);
                    // Se não criou registro
                    // 1 - grava log do erro
                    // 2 - seta mensagem, seta NAVIGATION
               }

          } catch (error) {
               // não acessou a api
               console.error("Erro ao acessar a api de gravação de dados:", error);
          }
     }

     return (
          <Fragment>
               <SeoTags
                    title={title}
                    description={description}
                    url="https://www.superbuxx.com"
               />

               {usuario ? null : (
                    <>
                         <div className="container-fluid banner-header-homepage text-white text-center py-3">
                              <h1>{t('homeh1a')}</h1>
                              <div className="paragrafo-homepage-log-out">
                                   <p>{t('hometranform')}</p>
                              </div>
                              <div className="row">
                                   <div className="buttons-header">
                                        <button className="buxx-btn" onClick={signupGoogle}>
                                             <img src="../src/assets/icons/google-icon-button.png" />
                                             <span>{t('contwithgoogle')}</span>
                                        </button>
                                        <a href="/signupmail" style={{ textDecoration: "none" }}>
                                             <button type='button' className="buxx-btn outlined">
                                                  <img src="../src/assets/icons/icon-email-button.svg" />
                                                  <span> {t('contwithemail')}</span>
                                             </button>
                                        </a>
                                   </div>
                              </div>
                              <div className="paragrafo-homepage-log-out">
                                   <p>{t('dohaveaccnt')} <a href="/login">{t('connect')}</a></p>
                              </div>
                         </div>

                         <div className="container como-ganhar">
                              <div className="row justify-content-center">
                                   <div className="block-header">
                                        <h2 className="block-title">{t('howtowin')}</h2>
                                   </div>
                                   <div className="col-lg-4 mb-lg-0 col-md-12 mb-4">
                                        <div className="card-custom">
                                             <div className="icon"><img src="../src/assets/icons/login-homepage.svg" /></div>
                                             <h3>{t('register')}</h3>
                                             <p>{t('createaccntforfree')}</p>
                                        </div>
                                   </div>
                                   <div className="col-lg-4 mb-lg-0 col-md-12 mb-4">
                                        <div className="card-custom">
                                             <div className="icon"><img src="../src/assets/icons/participe-homepage.svg" /></div>
                                             <h3>{t('participate')}</h3>
                                             <p>{t('completeoffers')}</p>
                                        </div>
                                   </div>
                                   <div className="col-lg-4 mb-lg-0 col-md-12 mb-4">
                                        <div className="card-custom">
                                             <div className="icon"><img src="../src/assets/icons/receba-homepage.svg" /></div>
                                             <h3>{t('receive')}</h3>
                                             <p>{t('changebuxxpix')}</p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </>
               )
               }

               {
                    usuario ? (
                         <Fragment>
                              {offerDetailUrl ? (
                                   <div className="offers-banners">
                                        <a href={offerDetailUrl}>
                                             <img className="responsive-image desktop" src={desktopPath} alt="Banner Desktop" />
                                        </a>
                                        <a href={offerDetailUrl}>
                                             <img className="responsive-image mobile" src={mobilePath} alt="Banner Mobile" />
                                        </a>
                                   </div>
                              ) : null}

                              <div className="container-fluid banner-header text-white text-center py-3">
                                   <h1>{t('douwannawin')}</h1>
                                   <div className="paragrafo-homepage-log-out">
                                        <p>{t('homediscover')}</p>
                                   </div>
                              </div>
                              <div id="logged-in" className="container">
                                   <div className="row justify-content-between">
                                        <div className="col-12">
                                             <div className="cards">
                                                  <ShowCardsDestaques
                                                       id="alea-destaques"
                                                       max="6"
                                                  />
                                                  {/* <ShowCardsDestaques
                                                  id="7"
                                                  max="6"
                                                  /> */}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </Fragment>
                    ) : null
               }

     
               <div className="container">
                    <div className="row parceiro-container spacing-box-top">
                         <div className="block-header">
                              <h2 className="block-title mt-5">{t('offersmenu')}</h2>
                              <div className="paragrafo-homepage-log-out">
                                   <p>{t('homeowsubtitle')}</p>
                              </div>
                         </div>
                         <Parceiros />
                    </div>
               </div>

               <div className="container-fluid container-spacing block-bottom-spacing" style={{ marginBottom: "9rem" }}>
                    <div className="row justify-content-center">
                         <div className="block-header">
                              <h2 className="block-title mt-5">{t('expresstitleh1')}</h2>
                              <div className="paragrafo-homepage-log-out">
                                   <p>{t('expresstxthead')}</p>
                              </div>
                         </div>
                    </div>
                    <ShowCardsHome
                         id="16"
                         max="8"
                    />
                    <div className="row">
                         <div className="col-12">
                              <div className="button-see-all">
                                   <a href="/express"><button className="btn btn-custom">{t('seeall')}</button></a>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="container-fluid container-spacing block-bottom-spacing">
                    <div className="row justify-content-center">
                         <div className="block-header">
                              <h2 className="block-title">{t('mostpopular')}</h2>
                              <div>
                                   <p>{t('participatetxt')}</p>
                              </div>
                         </div>
                    </div>
                    <ShowCardsHome
                         id="12"
                    />
               </div>

               <ShowCardPremium
                    id="14"
                    max="1"
               />

               {/* <div className="container block-bottom-spacing">
                    <div className="row justify-content-center">
                         <div className="col-12">
                              <div className="block-header">
                                   <h2 className="block-title">{t('wincashback')}</h2>
                                   <p>{t('cashbacktxt')}</p>
                              </div>
                         </div>
                    </div>
                    <div className="row">
                         <ShowCardsCashback
                              id="8"
                         />
                    </div>
               </div> */}

               <div className="container-fluid container-spacing block-bottom-spacing">
                    <div className="row justify-content-center">
                         <div className="block-header">
                              <h2 className="block-title mt-5">{t('testgame')}</h2>
                              <div>
                                   <p>{t('testgametxt')}</p>
                              </div>
                         </div>
                    </div>
                    <ShowCardsHome
                         id="4"
                         max="8"
                    />
                    <div className="row">
                         <div className="col-12">
                              <div className="button-see-all">
                                   <a href="/games"><button className="btn btn-custom">{t('seeall')}</button></a>
                              </div>
                         </div>
                    </div>
               </div>


               <ShowCardPremium
                    id="15"
                    max="1"
               />

               <div className=" spacing-box-top">
                    <div className="container-fluid container-spacing block-bottom-spacing">
                         <div className="row justify-content-center">
                              <div className="block-header">
                                   <h2 className="block-title">{t('winwithbet')}</h2>
                                   <div>
                                        <p>{t('winwithbettxt')}</p>
                                   </div>
                              </div>
                         </div>
                         <ShowCardsHome
                              id="10"
                              max="8"
                         />
                         <div className="row">
                              <div className="col-12">
                                   <div className="button-see-all">
                                        <a href="/bet"><button className="btn btn-custom">{t('seeall')}</button></a>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="spacing-box-top">
                    <div className="container-fluid container-spacing block-bottom-spacing">
                         <div className="row justify-content-center">
                              <div className="block-header">
                                   <h2 className="block-title">{t('bestrecovery')}</h2>
                                   <div>
                                        <p>{t('bestrecoverytxt')}</p>
                                   </div>
                              </div>
                         </div>
                         <ShowCardsHome
                              id="5"
                              max="4"
                         />
                         <div className="row">
                              <div className="col-12">
                                   <div className="button-see-all">
                                        <a href="/pesquisas"><button className="btn btn-custom">{t('seeall')}</button></a>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="container">
                    <div className="row parceiro-container spacing-box-top">
                         <div className="title-parceiros">
                              <h2>{t('allowtitle')}</h2>
                         </div>
                         <Parceiros />
                    </div>
               </div>
               <Suspense fallback={<>...</>}>
                    <Stats />
                    <AboutBox />
                    <FaqHome />
               </Suspense>

          </Fragment >

     )
}
export default Home